<template>
	<div>
		<CCard class="p-2">
			<form @submit.prevent="submit">
				<CCardHeader>
					<CRow style="color: #1352a1;">
						<CCol lg="6">
							<h5 v-if="!editMode">New Personnel Renewal Reminder </h5>
							<h5 v-else>Update Personnel Renewal Reminder</h5>
						</CCol>
						<CCol lg="6">
							<div style="text-align: right">
								<CButton size="sm" class="btn btn-ghost-dark mr-1" @click="backToTable()">Cancel</CButton>
								<CButton class="px-3 mr-1" size="sm" color="info" type="submit" :disabled="is_validated"><i class="fa fa-save"></i> Save</CButton>
							</div>
						</CCol>
					</CRow>
				</CCardHeader>
				<CCardBody class="card-form">
					<CCard class="pl-3 pr-3">
						<CRow class="mt-3 mb-2">
							<CCol lg="12">
								<h5>Personnel Renewal Details</h5>
							</CCol>
						</CRow>
						<CRow>
							<CCol lg="12">
								<div class="form-group">
									<label>Personnel <span class="text-danger">*</span></label>
									<v-select label="name" :options="personnelList.data" :reduce="item => item.id"
										v-model="dataParams.personnel_id" placeholder="-Select-">
										<template #search="{ attributes, events }">
											<input class="vs__search" :required="!dataParams.personnel_id"
												v-bind="attributes" v-on="events" />
										</template>
									</v-select>
								</div>

							</CCol>
						</CRow>

						<CRow>
							<CCol lg="12">
								<div class="form-group">
									<label>Renewal Type<span class="text-danger">*</span></label>
									<v-select label="setting_name" :options="licenseTypeList.data" :reduce="item => item.id" 
										v-model="dataParams.license_renewal_type_id" placeholder="-Select-">
										<template #search="{ attributes, events }" disabled>
											<input class="vs__search" :required="!dataParams.license_renewal_type_id"
												v-bind="attributes" v-on="events"/>
										</template>
									</v-select>
								</div>
							</CCol>
						</CRow>
						<CRow>
							<CCol lg="12">
								<div class="form-group">
									<label>Due Date
										<span class="text-danger">*</span>
									</label>
									<!-- <Datetime format="MM-DD-YYYY" 
							v-model="dataParams.delivery_date" 
							:required="true" 
							placeholder="Delivery date"></Datetime> -->

									<Datepicker input-class="form-control bg-white" v-model="dataParams.due_date"
										placeholder="" :typeable="true" required>
									</Datepicker>
								</div>
							</CCol>
						</CRow>
						<hr />
						<CRow>
							<CCol lg="6">
								<label style="font-size:13px">
									Time Due Soon Threshold <span class="text-danger">*</span><i class="fa fa-question-circle ml-2"
										title="The amount of time in advance you consider this reminder to be due soon. (For example: Every 2 weeks)"></i>
								</label>
								<CInput 
									placeholder="Enter number" 
									v-model="dataParams.time" 
									@keypress="isNumber($event)"
									min="1"
									required /> 
							</CCol>
							<CCol lg="6">
							<label style="font-size:13px">
								&nbsp;
							</label>
							<v-select label="setting_name" :options="[
								{
									id: 'days',
									setting_name: 'Days'
								},
								{
									id: 'weeks',
									setting_name: 'Weeks'
								},
								{
									id: 'months',
									setting_name: 'Months'
								},
								{
									id: 'years',
									setting_name: 'Years'
								}
							]" :reduce="item => item.id" 
								v-model="dataParams.threshold_unit" placeholder="-Select-">
								<template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!dataParams.threshold_unit"
												v-bind="attributes"
												v-on="events"
											/>
							</template>

							</v-select>
						

						</CCol>
						</CRow>
						<CRow>
							<CCol lg="12">
								<label>
									Notified Email <span class="text-danger">*</span>
								</label>
								<CInput placeholder="" v-model="dataParams.emails" required />
								<div class="text-info mb-2" style="margin-top: -15px; font-size:11px">Enter multiple email
									addresses, separated by comma.</div>
							</CCol>
						</CRow>
						<CRow>
							<CCol lg="12">
								<label>
									Remarks
								</label>
								<CInput placeholder="" v-model="dataParams.remarks" />
							</CCol>
						</CRow>
					</CCard>
				</CCardBody>
			</form>
		</CCard>
	</div>
</template>
<script>
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import '../../style.css';
import moment from 'moment';
export default {
	mounted() {
		if (this.$route.params.id != undefined) {
			this.editMode = true;
			this.validateLicenseTask();
		}
		if (this.$route.params.id) {
			this.getData();
		}
		this.getPersonnel();
	},
	data() {
		return {
			current_tab: "All",
			count: 0,
			title: '',
			isLoading: false,
			config,
			moment,
			dataParams: {
				personnel_id: '',
				license_renewal_type_id: '',
				threshold_unit: '',
				due_date: '',
				time: '',
				emails: '',
				remarks: '',
			},
			editMode: false,
			selectedRow: {},
			personnelList: {
				data: []
			},
			licenseTypeList: {
				data: []
			},
			is_validated:true,
		}
	},
	name: '',
	components: { Search, Datepicker, vSelect },
	methods: {
		backToTable() {
			this.$router.push('/data/license_renewal_reminder')
		},
		getData(){
		    this.$showLoading(true)
			axios.get(config.api_path+"/license-renewal-reminder/"+this.$route.params.id)
			.then(response=>{
		        this.$showLoading(false)
				this.dataParams = response.data.data;
		        // this.dataParams.emails = JSON.parse(this.dataParams.emails).join(', ');
			})
				.catch(err => {
				this.$showLoading(false)
			}) 
		}, 
		getPersonnel() {
			axios.get(config.api_path + '/reference/personnel-list')
			.then(response => {
				this.personnelList = response.data;
			})
		},
		getLicenseTask() {
			// this.licenseTypeList = [];
			axios.get(config.api_path + '/reference/settings-list', {
				params: {
					setting_type: 'license_renewal_task'
				}
			})
			.then(response => {
				this.licenseTypeList = response.data;
			})
		},
		validateLicenseTask(){
			if(this.dataParams.license_renewal_type_id){
				axios.get(config.api_path + '/license-renewal-reminder-type-validate', {
					params: {
						license_renewal_id: this.$route.params.id,
						license_renewal_type_id: this.dataParams.license_renewal_type_id,
						personnel_id: this.dataParams.personnel_id,
					}
				})
				.then(response => {
					if(response.data.status){
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: 'Renewal type for this personnel is already exist.',
						})
						this.is_validated = true;
					}
					else{
						this.is_validated = false;
					}
				})
			}
		},
		submit() {
			
			var ax = {};
			
			if (this.editMode) {
				ax = axios.put(config.api_path + "/license-renewal-reminder/" + this.selectedRow.id, this.dataParams)
			}
			else {
				ax = axios.post(config.api_path + "/license-renewal-reminder", this.dataParams)
			}
			ax.then(response => {
				var text = 'Personnel Renewal successfully added!';
				if (this.editMode) {
					text = 'Personnel Renewal successfully updated!';
				}
				Swal.fire({
					title: 'Success!',
					text,
					icon: 'success',
				})
				this.formModal = false
				this.editMode = false;
				this.dataParams = {
					personnel_id: '',
					license_renewal_type_id: '',
					threshold_unit: '',
					due_date: '',
					time: '',
					emails: '',
					remarks: '',
				}
				this.isLoading = false;
				console.log(response.data.data);
				this.$router.push('/data/view_license_renewal/' + response.data.data.id)
			})
				.catch(e => {
					this.isLoading = false;
				})
		},
	},
	watch: {
		'dataParams.personnel_id': function(){
			this.getLicenseTask();
			this.validateLicenseTask();
		},
		'dataParams.license_renewal_type_id': function(){
			this.validateLicenseTask();
		}
  	}
}
</script>
    