var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',{staticClass:"p-2"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('CCardHeader',[_c('CRow',{staticStyle:{"color":"#1352a1"}},[_c('CCol',{attrs:{"lg":"6"}},[(!_vm.editMode)?_c('h5',[_vm._v("New Personnel Renewal Reminder ")]):_c('h5',[_vm._v("Update Personnel Renewal Reminder")])]),_c('CCol',{attrs:{"lg":"6"}},[_c('div',{staticStyle:{"text-align":"right"}},[_c('CButton',{staticClass:"btn btn-ghost-dark mr-1",attrs:{"size":"sm"},on:{"click":function($event){return _vm.backToTable()}}},[_vm._v("Cancel")]),_c('CButton',{staticClass:"px-3 mr-1",attrs:{"size":"sm","color":"info","type":"submit","disabled":_vm.is_validated}},[_c('i',{staticClass:"fa fa-save"}),_vm._v(" Save")])],1)])],1)],1),_c('CCardBody',{staticClass:"card-form"},[_c('CCard',{staticClass:"pl-3 pr-3"},[_c('CRow',{staticClass:"mt-3 mb-2"},[_c('CCol',{attrs:{"lg":"12"}},[_c('h5',[_vm._v("Personnel Renewal Details")])])],1),_c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Personnel "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('v-select',{attrs:{"label":"name","options":_vm.personnelList.data,"reduce":function (item) { return item.id; },"placeholder":"-Select-"},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.dataParams.personnel_id}},'input',attributes,false),events))]}}]),model:{value:(_vm.dataParams.personnel_id),callback:function ($$v) {_vm.$set(_vm.dataParams, "personnel_id", $$v)},expression:"dataParams.personnel_id"}})],1)])],1),_c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Renewal Type"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('v-select',{attrs:{"label":"setting_name","options":_vm.licenseTypeList.data,"reduce":function (item) { return item.id; },"placeholder":"-Select-"},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.dataParams.license_renewal_type_id}},'input',attributes,false),events))]}}]),model:{value:(_vm.dataParams.license_renewal_type_id),callback:function ($$v) {_vm.$set(_vm.dataParams, "license_renewal_type_id", $$v)},expression:"dataParams.license_renewal_type_id"}})],1)])],1),_c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Due Date "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('Datepicker',{attrs:{"input-class":"form-control bg-white","placeholder":"","typeable":true,"required":""},model:{value:(_vm.dataParams.due_date),callback:function ($$v) {_vm.$set(_vm.dataParams, "due_date", $$v)},expression:"dataParams.due_date"}})],1)])],1),_c('hr'),_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('label',{staticStyle:{"font-size":"13px"}},[_vm._v(" Time Due Soon Threshold "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('i',{staticClass:"fa fa-question-circle ml-2",attrs:{"title":"The amount of time in advance you consider this reminder to be due soon. (For example: Every 2 weeks)"}})]),_c('CInput',{attrs:{"placeholder":"Enter number","min":"1","required":""},on:{"keypress":function($event){return _vm.isNumber($event)}},model:{value:(_vm.dataParams.time),callback:function ($$v) {_vm.$set(_vm.dataParams, "time", $$v)},expression:"dataParams.time"}})],1),_c('CCol',{attrs:{"lg":"6"}},[_c('label',{staticStyle:{"font-size":"13px"}},[_vm._v("   ")]),_c('v-select',{attrs:{"label":"setting_name","options":[
							{
								id: 'days',
								setting_name: 'Days'
							},
							{
								id: 'weeks',
								setting_name: 'Weeks'
							},
							{
								id: 'months',
								setting_name: 'Months'
							},
							{
								id: 'years',
								setting_name: 'Years'
							}
						],"reduce":function (item) { return item.id; },"placeholder":"-Select-"},scopedSlots:_vm._u([{key:"search",fn:function(ref){
						var attributes = ref.attributes;
						var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.dataParams.threshold_unit}},'input',attributes,false),events))]}}]),model:{value:(_vm.dataParams.threshold_unit),callback:function ($$v) {_vm.$set(_vm.dataParams, "threshold_unit", $$v)},expression:"dataParams.threshold_unit"}})],1)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('label',[_vm._v(" Notified Email "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('CInput',{attrs:{"placeholder":"","required":""},model:{value:(_vm.dataParams.emails),callback:function ($$v) {_vm.$set(_vm.dataParams, "emails", $$v)},expression:"dataParams.emails"}}),_c('div',{staticClass:"text-info mb-2",staticStyle:{"margin-top":"-15px","font-size":"11px"}},[_vm._v("Enter multiple email addresses, separated by comma.")])],1)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('label',[_vm._v(" Remarks ")]),_c('CInput',{attrs:{"placeholder":""},model:{value:(_vm.dataParams.remarks),callback:function ($$v) {_vm.$set(_vm.dataParams, "remarks", $$v)},expression:"dataParams.remarks"}})],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }